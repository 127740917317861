// eslint-disable-next-line
import {
  UserLayout,
  /* ScreenView */
  //PageView
} from '@/layouts'

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/Home',
    component: UserLayout,
    redirect: '/home/Login',
    hidden: true,
    children: [{
      path: '/Home/Login',
      name: 'Login',
      component: () => import('@/views/home/Login')
    }]
  },
  {
    path: '/',
    title:'项目管理',
    component: () => import('@/views/project/index')
  },
  {
    path: '/realname',
    title:'实名制',
    component: () => import('@/views/realname/index')
  },
  {
    path: '/video',
    title:'视频监控',
    component: () => import('@/views/video/index')
  },
  {
    path: '/aerial-photo',
    title:'航拍全景',
    component: () => import('@/views/video/aerialPhoto')
  },
  {
    path: '/monitor',
    title:'全景监控',
    component: () => import('@/views/monitor/index')
  },
  {
    path: '/evn',
    title:'环境',
    component: () => import('@/views/env/index')
  },
  {
    path: '/tower',
    title:'塔吊',
    component: () => import('@/views/safety/tower')
  },
  {
    path: '/elevator',
    title:'升降机',
    component: () => import('@/views/safety/elevator')
  },
  {
    path: '/helmet',
    title:'安全帽',
    component: () => import('@/views/safety/helmet')
  },
  {
    path: '/safety',
    title:'安全管理',
    component: () => import('@/views/check/safety/index')
  },
  {
    path: '/quality',
    title:'质量管理',
    component: () => import('@/views/check/quality/index')
  },
  {
    path: '/notopen',title: '功能未开放',
    component: () => import('@/views/notopen')
  },
  {
    path: '/403',title: '403',
    component: () => import('@/views/exception/403')
  },
  {
    path: '/404',title: '404',
    component: () => import('@/views/exception/404')
  },
  {
    path: '/500',title: '500',
    component: () => import('@/views/exception/500')
  }
]