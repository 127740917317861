import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dataV from '@jiaminghi/data-view'

// import { VueAxios } from './utils/request'
import AxiosPlugin from '@/utils/plugin/axios-plugin'

Vue.use(dataV)
Vue.use(AxiosPlugin)

// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon'
import 'vue-awesome/icons/chart-bar.js'
import 'vue-awesome/icons/chart-area.js'
import 'vue-awesome/icons/chart-pie.js'
import 'vue-awesome/icons/chart-line.js'
import 'vue-awesome/icons/align-left.js'

import './core/use'
import './permission' // permission control
import './utils/filter' // global filter
import operatorPlugin from './utils/plugin/operator-plugin'
// 全局注册图标
Vue.component('icon', Icon)
Vue.use(operatorPlugin)
// 适配flex
import '@/common/flexible.js'

// 引入全局css
import './assets/scss/style.scss'
import "./assets/scss/index.scss"

import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video //引入Video播放器
//引入echart
import echarts from 'echarts'
Vue.prototype.$echarts = echarts


import JsCookies from 'js-cookie'
Vue.prototype.$cookies = JsCookies

import vuetimeline from "@growthbunker/vuetimeline"
Vue.use(vuetimeline)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')