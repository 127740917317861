
const proInfoKey = 'proInfoCache'
const proInfosKey = 'proInfosCache'
let ProInfoCache = {
    getProInfo() {
        return JSON.parse(localStorage.getItem(proInfoKey));
    },
    setProInfo(obj) {
        localStorage.setItem(proInfoKey, JSON.stringify(obj))
    },
    deleteProInfo() {
        localStorage.removeItem(proInfoKey)
    },
    getPros() {
        return JSON.parse(localStorage.getItem(proInfosKey))
    },
    setPros(obj) {
        localStorage.setItem(proInfosKey, JSON.stringify(obj))
    },
    deletePros() {
        localStorage.removeItem(proInfosKey)
    }
}
export default ProInfoCache