<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { AppDeviceEnquire } from "@/utils/mixin";

export default {
  mixins: [AppDeviceEnquire],
};
</script>
<style lang="scss">
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
