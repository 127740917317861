<template>
  <div id="screenView">
    <dv-full-screen-container class="bg">
      <div class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 style="width: 33.3%; height: 0.0625rem" />
          <div class="d-flex jc-center">
            <dv-decoration-8 :color="['#568aea', '#000000']"
                             style="width: 2.5rem; height: 0.625rem" />
            <div class="title">
              <span class="title-text">科技智慧工地大屏</span>
              <dv-decoration-6 class="title-bototm"
                               :reverse="true"
                               :color="['#50e3c2', '#67a1e5']"
                               style="width: 3.125rem; height: 0.1rem" />
            </div>
            <dv-decoration-8 :reverse="true"
                             :color="['#568aea', '#000000']"
                             style="width: 2.5rem; height: 0.625rem" />
          </div>
          <dv-decoration-10 style="width: 33.3%; height: 0.0625rem; transform: rotateY(180deg)" />
        </div>

        <!-- 第二行 -->
        <div class="d-flex jc-between px-2">
          <div class="d-flex"
               style="width: 40%">
            <div class="react-right ml-4"
                 style="
                width: 6.25rem;
                text-align: left;
                background-color: #0f1325;
              ">
              <span class="react-before"></span>
              <span class="text">劳务管理</span>
            </div>
            <div class="react-right ml-3"
                 style="background-color: #0f1325">
              <span class="text colorBlue">数据分析2</span>
            </div>
          </div>
          <div style="width: 40%"
               class="d-flex">
            <div class="react-left bg-color-blue mr-3">
              <span class="text fw-b">vue-big-screen</span>
            </div>
            <div class="react-left mr-4"
                 style="
                width: 6.25rem;
                background-color: #0f1325;
                text-align: right;
              ">
              <span class="react-after"></span>
              <span class="text">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
            </div>
          </div>
        </div>
        <route-view></route-view>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mixinDevice } from "@/utils/mixin";
import RouteView from "./RouteView";

export default {
  name: "ScreenView",
  components: { RouteView },
  mixins: [mixinDevice],
  data () {
    return {
      pageTitle: null,
      description: null,
      linkList: [],
      extraImage: "",
      search: false,
      tabs: {},
    };
  },
  computed: {
    ...mapState({
      multiTab: (state) => state.app.multiTab,
    }),
  },
  beforeDestroy () {
    document.body.classList.remove("screenView");
  },
  mounted () {
    document.body.classList.add("screenView");
    this.tabs = this.directTabs;
    this.getPageMeta();
  },
  updated () {
    this.getPageMeta();
  },
  methods: {
    getPageMeta () {
      // eslint-disable-next-line
      this.pageTitle =
        typeof this.title === "string" || !this.title
          ? this.title
          : this.$route.meta.title;

      const content = this.$refs.content;
      if (content) {
        if (content.pageMeta) {
          Object.assign(this, content.pageMeta);
        } else {
          this.description = content.description;
          this.linkList = content.linkList;
          this.extraImage = content.extraImage;
          this.search = content.search === true;
          this.tabs = content.tabs;
        }
      }
    },
  },
};
</script>