
import JsCookies from 'js-cookie'
const tokenKey = 'jwtToken'
import defaultSettings from '@/config/defaultSettings'

let TokenCache = {
  getToken () {
    return JsCookies.get(tokenKey)
       // return localStorage.getItem(tokenKey)
    },
    setToken (newToken) {
      return JsCookies.set(tokenKey, newToken, { domain: ['localhost','39.106.74.108','47.122.30.104','10.101.15.140'].includes(document.domain) ? document.domain : defaultSettings.domain })
    },
    deleteToken () {
      JsCookies.remove(tokenKey, { domain: ['localhost','39.106.74.108','47.122.30.104','10.101.15.140'].includes(document.domain) ? document.domain : defaultSettings.domain })
      }
}

export default TokenCache