import { Axios } from "@/utils/plugin/axios-plugin"
import TokenCache from "@/utils/cache/TokenCache";
import ProInfoCache from "@/utils/cache/ProInfoCache";
import * as signalR from "@aspnet/signalr";

let permissions = []
let inited = false
let connected=false

let OperatorCache = {
    info: {},
    pros: [],
    connection: {},
    inited() {
        return inited
    },
    init(callBack) {
        if (inited)
            callBack()
        else {
            Axios.post('/BaseManage/Home/GetOperatorProsInfo').then(resJson => {
                this.info = resJson.Data.UserInfo
                this.pros=resJson.Data.Pros
                ProInfoCache.setPros(this.pros)
                let pro = ProInfoCache.getProInfo()
                if((pro== null || !pro)  && this.pros.length > 0){
                    ProInfoCache.setProInfo(resJson.Data.Pro)
                }
                if(pro && this.pros.filter((x)=>x.Id == pro.Id).length <=0){
                    ProInfoCache.setProInfo(resJson.Data.Pro)                    
                }
                permissions = resJson.Data.Permissions
                inited = true
                callBack()
            })
        }
    },
    loadChooseProInfo(proId){
        Axios.post('/BaseManage/Home/GetOperatorProInfo',{ProId:proId}).then(resOperatorPro => {
            if(resOperatorPro.Success){
                ProInfoCache.setProInfo(resOperatorPro.Data)
            }
        })
    },
    getConnect() { 
        if (connected)
        {
            return this.connection;
        }
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl("/bigScreenHub", {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
            })
            .configureLogging(signalR.LogLevel.Information)
            .build();

        if (this.connection) {
            this.connection
                .start()
                .then(() => {
                    this.connection.invoke(
                        "initializeAsync",
                        TokenCache.getToken()
                    );
                })
                .catch((err) => console.log(err));
            
            this.connection.on("connected", () => {
                console.log("Connected.", "primary");
            });

            this.connection.on("disconnected", () => {
                console.log("Disconnected, invalid token.", "danger");
            });
            connected=true
        }
        else {
             console.log("Disconnected.", "danger");
        }
        return this.connection
    },
    hasPermission(thePermission) {
        return permissions.includes(thePermission)
    },
    clear() {
        inited = false
        permissions = []
        this.info = {}
    }
}

export default OperatorCache